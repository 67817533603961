<script>
    import {required} from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import Cashless from '@/services/Cashless';
    import Swal from "sweetalert2";
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
    import Customers from '@/services/Customers';
    import _ from 'underscore';

    export default {
        components:{
            VueBootstrapTypeahead,
            validationMessages
        },
        props: {
            cardData: {
                type: Object
            },
            associateAction:{
                type:String
            }
        },
        watch: {
            cardData: function(newVal) {
                this.serial_e = newVal.serial;
                this.cvc_e = newVal.cvc;
            },
            customer: _.debounce(function(cust) { this.getCustomers(cust) }, 500)
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                customer: '',
                customerData: [],
                selectedCustomer: null,
                serial_e: '',
                cvc_e: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,

            };
        },
        validations: {
            customer: {
                required
            }
        },
        methods: {

            async getCustomers(query) {

                if(query.length > 0){
                    var filters = "search="+query;
                    const response = await Customers.getCustomers(filters)
                    const rawData = await response
                    var suggestions = [];
                    rawData.data.data.forEach(function (item) {
                        if(item.first_name){
                            var add_info = '';
                            if(item.email){
                                add_info = " ("+item.email+")";
                            }
                            suggestions.push({text:item.first_name + ' ' + item.middle_name + ' ' + item.last_name + add_info, value:item.id});
                        }
                    });

                    this.customerData = suggestions
                }
            },

            async changeCustomertoCardAssocation(){
                this.tryingToEdit = true;
                this.submitted = true;

                try {
                    if(this.associateAction == 'deassociate'){
                        await Cashless.deassociateCardFromUser(this.cardData.serial, {
                            serial:this.cardData.serial,
                            customer_id:this.cardData.customer[0].customer_id,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    }else if(this.associateAction == 'associate'){

                        if(!this.selectedCustomer){
                            this.customer = '';
                        }
                        this.$v.$touch()

                        if (this.$v.$invalid) {
                            this.tryingToEdit = false;
                            return;
                        } else {
                            await Cashless.associateCardToUser(this.cardData.serial, {
                                serial: this.cardData.serial,
                                cvc: this.cardData.cvc,
                                customer_id: this.selectedCustomer.value,
                                csrf_token: this.csrf_token
                            }).then((response) => {
                                this.successmsg(response);
                                this.closeModal();
                            }).catch(error => {
                                this.error = error.response.data.error ? error.response.data.error : "";
                                this.failedmsg(this.error)
                            }).finally(() => {
                                this.refreshData()
                                this.tryingToEdit = false;
                            })
                        }
                    }else{
                        this.failedmsg('Select available card balance action')
                    }

                } catch (error) {
                    console.log(error);
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }

                this.tryingToEdit = false;
                this.submitted = false
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

            resetProps(){
                this.customer = '';
                this.selectedCustomer = '';
            },
            cardAssociationTitle(){

                if(this.associateAction == 'associate'){
                    return 'Associate Card to Customer';
                }else if(this.associateAction == 'deassociate'){
                    return 'Deassociate Card from Customer';
                }
            }
        }
    };
</script>

<template>
    <b-modal v-model="showModal" @hidden="resetProps" id="customer_to_card_association_change" :title="cardAssociationTitle()" title-class="font-18" centered>
        <form @submit.prevent="changeCustomertoCardAssocation">
            <template v-if="associateAction === 'associate'">

                <b-form-group label="Serial" label-for="formrow-serial-input" class="mb-3">
                    <b-form-input
                            v-model.trim="serial_e"
                            type="text"
                            id="serial"
                            aria-describedby="serial-feedback"
                            disabled
                    >
                    </b-form-input>
                </b-form-group>

                <vue-bootstrap-typeahead
                        v-model="customer"
                        :data=customerData
                        :serializer="s => s.text"
                        @hit="selectedCustomer = $event"
                        :class="{
                          'is-invalid': submitted && $v.customer.$error,
                        }"
                />
                <validationMessages v-if="submitted" :fieldName="'Customer'" :validationName="$v.customer"></validationMessages>
            </template>
            <template v-else-if="associateAction === 'deassociate'">
                <h4 class="text-center">Are you sure you want to dissociate this card from customer ?</h4>
            </template>
            <template v-else>
                <h4 class="text-center">Card association can not be changed</h4>
            </template>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="changeCustomertoCardAssocation" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>
